import { useRouter } from "next/router";

import { useCallback, useContext, useState } from "react";

import Bugsnag from "@bugsnag/js";
import classNames from "classnames";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";

import FontAwesomeIcon from "components/FontAwesomeIcon";
import { NotificationContext } from "components/Notification/NotificationContext";
import useTheme from "hooks/useTheme";
import { fetchApi } from "utils/baseFetcher";
import { setSessionCookies } from "utils/setSessionCookies";

type SignInFormProps = {
  border?: boolean;
  onSwitch?: () => void;
};

const SignInForm = ({ border, onSwitch }: SignInFormProps) => {
  const router = useRouter();
  const { setNotification } = useContext(NotificationContext);

  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const isSubmitting = false;

  const signIn = useCallback(
    async (e) => {
      e.preventDefault();

      let hasError = false;

      if (password === "") {
        setPasswordError("is required");
        hasError = true;
      } else setPasswordError("");

      if (email === "") {
        setEmailError("is required");
        hasError = true;
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
          email || ""
        )
      ) {
        setEmailError("must be a valid email");
        hasError = true;
      } else setEmailError("");

      if (hasError) return;

      try {
        const payload = {
          account: {
            email,
            password,
          },
        };

        gtag?.("event", "sign_in");

        try {
          const data = await fetchApi("/api/v2/accounts/sign_in", {
            method: "POST",
            body: JSON.stringify(payload),
          });
          if (data?.token) {
            const account = await setSessionCookies(data, {
              registerDevice: true,
            });

            if (account?.interfaceSetting?.requiresOnboarding) {
              localStorage.setItem("firstLogin", "true");
            } else if (localStorage.getItem("firstLogin")) {
              localStorage.removeItem("firstLogin");
            }

            const reload = () => {
              if (["/signin", "/login"].includes(window.location.pathname)) {
                if (
                  !document.referrer ||
                  new URL(document.referrer).pathname.startsWith("/home") ||
                  new URL(document.referrer).pathname.startsWith("/signin") ||
                  new URL(document.referrer).pathname.startsWith("/login")
                ) {
                  window.location.href = "/dashboard";
                } else {
                  if (window.history.length < 3) {
                    window.location.href = "/dashboard";
                  } else {
                    window.history.back();
                  }
                }
              } else {
                window.location.reload();
              }
            };

            setTimeout(reload, 1000); // fallback
            gtag?.("event", "sign_in", {
              event_callback: reload,
            });
          } else if (data && data.errors) {
            setNotification(data.errors[0]?.title, "error");
          }
        } catch (err: any) {
          setNotification("Failed to sign in. Please try again.", "error");
          console.error("Sign in error: ", err);
          Bugsnag.notify(err, (event) => {
            event.setUser(undefined, email);
            event.addMetadata("inputs", {
              email,
            });
            return true;
          });
        }
      } catch (error) {
        console.error("This is a error: ", error);
      }
    },
    [email, password, setNotification]
  );

  const form = (
    <form className="flex flex-col text-center pt-[30px]" onSubmit={signIn}>
      <h2 className="text-2 mb-0">Sign In</h2>
      <div className="flex flex-col text-left mt-[15px] text-[15px] relative">
        <label htmlFor="email-input" className="font-bold">
          Email
          {emailError && (
            <span className="mx-[5px] text-[#c94962] font-medium">
              <FontAwesomeIcon
                className="mr-[5px]"
                icon={faExclamationCircle}
              />
              {emailError}
            </span>
          )}
        </label>
        <input
          className="h-[40px] px-[15px] mb-[15px] outline-none border border-solid border-[#ddd] focus:border-[#24a9a7] rounded-sm shadown-inner hover:shadow focus:shadow"
          name="email"
          type="email"
          autoComplete="username"
          id="email-input"
          onChange={(event) => setEmail(event.currentTarget.value)}
        />

        <label htmlFor="password-input" className="font-bold">
          Password
          {passwordError && (
            <span className="mx-[5px] text-[#c94962] font-medium">
              <FontAwesomeIcon icon={faExclamationCircle} />
              {passwordError}
            </span>
          )}
        </label>
        <input
          className="h-[40px] px-[15px] mb-[15px] outline-none border border-solid border-[#ddd] focus:border-[#24a9a7] rounded-sm shadown-inner hover:shadow focus:shadow"
          type="password"
          name="password"
          id="password-input"
          onChange={(event) => setPassword(event.currentTarget.value)}
        />
        <a
          rel="nofollow"
          href={`/reset-password?email=${email}`}
          className="absolute top-[110px] right-[10px] px-[10px] py-[5px] bg-[#eee] rounded-full text-[11px] leading-none text-smart-teal hover:text-smart-teal"
        >
          Forgot Password
        </a>
      </div>
      <button
        className={classNames(
          "bg-smart-teal hover:bg-[#1d8988] font-medium text-white leading-4 border-none rounded-full mt-[5px] py-3 px-4",
          theme === "bos" && "bg-[#5f686f] hover:bg-[#9a1421]"
        )}
        type="submit"
        disabled={isSubmitting}
      >
        SIGN IN
      </button>

      <label className="pt-[15px] text-[13px]">
        Don&apos;t have an account?{" "}
        <span
          className="text-smart-teal hover:text-smart-teal cursor-pointer"
          onClick={() => {
            onSwitch ? onSwitch() : router.push("/signup");
          }}
        >
          Sign Up Now
        </span>
      </label>
    </form>
  );

  if (border) return <div className="">{form}</div>;

  return form;
};

export default SignInForm;
