import Head from "next/head";
import { useRouter } from "next/router";

import { useEffect } from "react";

import { twMerge } from "tailwind-merge";

import SignInForm from "components/SignInForm";
import type { NextPageWithLayout } from "pages/_app";
import useAuthentication from "hooks/useAuthentication";
import { emberAppBaseUrl } from "utils/envVariables";

type LoginPageProps = {};

const title = "Sign In | Smartkarma";
const description = "Login to Smartkarma";

const LoginPage: NextPageWithLayout = ({}: LoginPageProps) => {
  const router = useRouter();
  const canonicalUrl =
    emberAppBaseUrl + (router.asPath === "/" ? "" : router.asPath);

  const { authenticated } = useAuthentication();

  useEffect(() => {
    if (authenticated) window.location.href = "/dashboard";
  }, [authenticated]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://assets.smartkarma.com/v3/img/smartkarma-research-reinvented-dark.png"
        />
      </Head>
      <div className="md:px-[15px] lg:px-[30px] flex flex-col min-h-[100vh] md:justify-center">
        <div
          className={twMerge(
            "p-[30px]",
            "lg:mx-auto mt-[65px] md:mt-[80px] mb-[20px] pt-[5px] pb-[30px]",
            "shadow bg-white rounded"
          )}
        >
          <div className="px-[15px] md:px-0 md:w-[470px] mx-auto">
            <SignInForm border={false} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
